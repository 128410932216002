import { CalendarReport } from '../interfaces/calendar-report-interface';
import { ClockInReportsData } from '../interfaces/clockin-reports-interface';
import { Employee } from '../interfaces/employee-interface';
import { LastLockResponse } from '../interfaces/last-lock-response';
import { LogData } from '../interfaces/log-interface';
import { StaffWorkTimeReportResponse } from '../interfaces/staff-work-time-report-response';
import { TopBotReport } from '../interfaces/top-bot-report';
import { useActiveWorkspaceSlice } from '../store/active-workspace-slice';
import http from './axios-instance';

const clockInReportsQueryKey = 'StaffClockInReports';
const getClockInReports = async (
  id: Employee['id'],
  from?: number | null | undefined,
  to?: number | null | undefined,
): Promise<ClockInReportsData> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const params = { staff_id: id, ...(from && { from }), ...(to && { to }) };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/report/clock`;
  const res = await http.get(url, { headers, params });
  return res.data;
};

const calendarReportsQueryKey = 'CalendarReports';
const getCalendarReports = async (params: {
  from: number;
  to: number;
}): Promise<CalendarReport[]> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/report/calendar`;
  const res = await http.get(url, { headers, params });
  return res.data;
};

const logsQueryKey = 'Logs';
const getLogs = async (params: {
  page?: number;
  offset?: number;
  start?: string;
  end?: string;
  causer_type?: 'App\\Models\\Merchant' | 'App\\Models\\Staff';
}): Promise<LogData> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/report/logs`;
  const res = await http.get(url, { headers, params });
  return res.data;
};

const logQueryKey = 'Log';
const getLog = async (logId: number): Promise<LogData['data'][0]> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const params = { log_id: logId };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/report/log`;
  const res = await http.get(url, { headers, params });
  return res.data;
};

const staffWorkTimeReportQueryKey = 'StaffWorkTimeReport';
const getStaffWorkTimeReport = async (
  workSpaceSubDomain: string,
  from: string,
  to: string,
  status: string,
): Promise<StaffWorkTimeReportResponse[]> => {
  const headers = { workSpaceSubDomain };
  const params = { from, to, status };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/report/per_workspace`;
  const res = await http.get(url, { headers, params });
  return res.data;
};

const getTopBotReportQueryKey = 'TopBottomReport';
const getTopBotReport = async (
  workSpaceSubDomain: string,
  from: string,
  to: string,
  count: number | null | undefined = 3,
  type: string | null | undefined = 'schedules', // ["schedules", "clocks", "owed", "extra", "un_schedules", "leave_requests", "schedules_breaks", "clocks_breaks", "early_comer", "late_comer", "early_leave", "late_leave"]
  direction: string | null | undefined = 'desc', // 'asc'
  status: string | null | undefined = 'active', // 'active', 'archived
): Promise<TopBotReport[]> => {
  const headers = { workSpaceSubDomain };
  const params = { from, to, count, type, direction, status };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/report/top_bot_x`;
  const res = await http.get(url, { headers, params });
  return res.data;
};

const getLastLockQueryKey = 'lastLock';
const getLastLock = async (workSpaceSubDomain: string): Promise<LastLockResponse['data']> => {
  const headers = { workSpaceSubDomain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/report/last_lock`;
  const res = await http.get<LastLockResponse>(url, { headers });
  return res.data.data;
};

const exportCalendar = async (data: {
  start: string;
  end: string;
  rows: (string | number)[][];
}): Promise<string> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/report/calendar_export`;
  const res = await http.post(url, data, { headers });
  return res.data;
};

const importCalendar = async (data: FormData): Promise<any> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/report/calendar_import`;
  const res = await http.post(url, data, { headers });
  return res.data;
};

const reportLock = async (to: number): Promise<any> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/report/lock`;
  const res = await http.post(url, { to }, { headers });
  return res.data;
};

const reportsService = {
  clockInReportsQueryKey,
  getClockInReports,
  calendarReportsQueryKey,
  getCalendarReports,
  logsQueryKey,
  getLogs,
  logQueryKey,
  getLog,
  exportCalendar,
  importCalendar,
  staffWorkTimeReportQueryKey,
  getStaffWorkTimeReport,
  reportLock,
  getLastLockQueryKey,
  getLastLock,
  getTopBotReportQueryKey,
  getTopBotReport,
};

export default reportsService;
