import { XMarkIcon } from '@heroicons/react/24/outline';
import Dialog from '@mui/material/Dialog';

interface InOutImageDialogProps {
  show: boolean;
  closeModal: () => void;
  title: string | null | undefined;
  src: string | null | undefined;
}

const InOutImageDialog = ({ show, closeModal, title, src }: InOutImageDialogProps) => {
  if (!src) return null;
  return (
    <Dialog onClose={() => closeModal()} open={show} fullWidth={false} maxWidth={'sm'}>
      <div className="flex flex-col max-w-[600px] p-6">
        <div className="flex items-center justify-between text-lg font-medium leading-6 text-gray-900">
          <div className="flex flex-col">
            <span className="leading-9 capitalize">{title}</span>
          </div>
          <button
            type="button"
            onClick={() => closeModal()}
            className="ml-6 transition border border-transparent rounded shadow-sm self-baseline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          >
            <XMarkIcon className="w-6 h-6" />
          </button>
        </div>
        <div className="flex items-center justify-between w-auto pt-9 md:mx-0">
          <img
            src={src}
            alt={title ?? 'image'}
            className="w-full h-full max-w-[512px] max-h-[512px] object-cover"
          />
        </div>
      </div>
    </Dialog>
  );
};

export default InOutImageDialog;
