import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import reportsService from '../services/reports-service';
import { TopBotReport } from '../interfaces/top-bot-report';

export function useTopBotReport(
  workSpaceSubDomain: string,
  from: string | null | undefined,
  to: string | null | undefined,
  count: number | null | undefined = 3,
  type: string | null | undefined = 'schedules',
  direction: string | null | undefined = 'desc',
  status: string | null | undefined = 'active',
  configOptions: UseQueryOptions<TopBotReport[]> = {},
) {
  return useQuery({
    enabled: Boolean(workSpaceSubDomain) && Boolean(from) && Boolean(to),
    queryKey: [
      reportsService.getTopBotReportQueryKey,
      from,
      to,
      workSpaceSubDomain,
      count,
      type,
      direction,
      status,
    ],
    queryFn: () =>
      reportsService.getTopBotReport(
        workSpaceSubDomain!,
        from!,
        to!,
        count,
        type,
        direction,
        status,
      ),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 30,
    retry: 2,
    ...configOptions,
  });
}
