import { useCalendarSlice } from '../../../store/calendar-slice';
import { dateUtc, formatDateToTimeZone } from '../../../utils/format-date';
// import { allowToModify } from '../../../utils/allow-to-modify';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid';
import TrashIcon from '~icons/carbon/trash-can';
import { useState } from 'react';
import leaveService from '../../../services/leave-service';
import toast from 'react-hot-toast';
import ReasonModal from './reason-modal';
import { LeaveReq } from '../../../interfaces/calendar-leave-request-interface';
import { isSameDay } from 'date-fns';
import calendarService from '../../../services/calendar-service';
import ChangeStatusModal from '../../leaves-of-absences/components/change-status-modal';
import { LeaveReqDetails } from '../../leaves-of-absences/leaves-of-absences';
import { useDeleteLeaveRequest } from '../../../hooks/use-delete-leave-request';
import Confirmation from '../../../components/libs/confirmation';
import { customToastError } from '../../../utils/custom-toast-error';
import { Tooltip } from '@mui/material';

const LeaveRequestsList = () => {
  const queryClient = useQueryClient();
  const activeResource = useCalendarSlice((state) => state.activeResource);
  const updateActiveResource = useCalendarSlice((state) => state.updateActiveResource);
  const selectedDate = useCalendarSlice((state) => state.selectedDate);
  const toggleScheduleModal = useCalendarSlice((state) => state.updateShowScheduleModal);
  const [showReasonModal, setShowReasonModal] = useState(false);
  const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);
  const [reqDetails, setReqDetails] = useState<LeaveReqDetails | null>(null);
  // const canModify = selectedDate ? allowToModify(selectedDate) : false;
  const deleteLeaveRequest = useDeleteLeaveRequest({
    onSuccess: (data, id) => {
      toast.success(data?.message);
      updateActiveResource({
        ...activeResource!,
        leaveRequests: activeResource!.leaveRequests.filter((lr) => {
          return lr.id !== id;
        }),
      });
    },
  });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedLeaveId, setSelectedLeaveId] = useState<number | null>(null);
  const leaveRequests =
    activeResource?.leaveRequests.filter((ev) => {
      if (selectedDate) {
        return isSameDay(dateUtc(ev.start_at), dateUtc(selectedDate));
      }
      return false;
    }) ?? [];

  const { mutate, isLoading } = useMutation(leaveService.updateLeaveRequest, {
    onSuccess: (data) => {
      toast.success(`Status Changed.`);
      setReqDetails(null);
      queryClient.invalidateQueries([calendarService.leaveRequestsQueryKey]);
      updateActiveResource({
        ...activeResource!,
        leaveRequests: activeResource!.leaveRequests.map((lr) => {
          if (lr.id === data.id) {
            return data;
          }
          return lr;
        }),
      });
    },
    onError(error, variables, context) {
      customToastError('Error', (error as any).response.data.message ?? 'Sorry there was an error');
    },
  });

  const handleUpdateRequest = (
    id: LeaveReq['id'],
    status: LeaveReq['status'],
    leaveId: LeaveReq['leave_id'],
    staffId: LeaveReq['staff_id'],
  ) => {
    setReqDetails({ id, status, leaveId, staffId });
    openChangeStatusModal();
  };

  const updateLeaveRequest = (status: LeaveReq['status']) => {
    if (isLoading) {
      return;
    }
    if (reqDetails) {
      if (status === 'declined') {
        setReqDetails({
          id: reqDetails.id,
          status: 'declined',
          leaveId: reqDetails.leaveId,
          staffId: reqDetails.staffId,
        });
        openReasonModal();
      } else {
        mutate({
          leave_request_id: reqDetails.id,
          status,
          ...(reqDetails.leaveId && { leave_id: reqDetails.leaveId }),
        });
      }
    }
  };

  const handleAcceptRequest = (
    id: LeaveReq['id'],
    status: LeaveReq['status'],
    leaveId: LeaveReq['leave_id'],
    staffId: LeaveReq['staff_id'],
  ) => {
    if (isLoading) {
      return;
    }
    if (leaveId) {
      mutate({ leave_request_id: id, status: 'approved', leave_id: leaveId });
    } else {
      setReqDetails({ id, status, leaveId, staffId });
      openChangeStatusModal();
    }
  };

  const handleRejectRequest = (
    id: LeaveReq['id'],
    leaveId: LeaveReq['leave_id'],
    staffId: LeaveReq['staff_id'],
  ) => {
    setReqDetails({ id, status: 'declined', leaveId, staffId });
    openReasonModal();
  };

  const addReason = (description = '') => {
    if (isLoading) {
      return;
    }
    if (reqDetails) {
      mutate({
        description,
        leave_request_id: reqDetails.id,
        status: reqDetails.status,
        leave_id: reqDetails.leaveId ?? undefined,
      });
    }
  };

  const openReasonModal = () => {
    setShowReasonModal(true);
  };

  const closeReasonModal = () => {
    setShowReasonModal(false);
  };

  const openChangeStatusModal = () => {
    setShowChangeStatusModal(true);
  };

  const closeChangeStatusModal = () => {
    setShowChangeStatusModal(false);
  };

  const handleCloseModal = () => {
    toggleScheduleModal(false);
  };

  const handleOpenConfirmation = () => {
    setShowConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleDeleteLeave = (id: number) => {
    if (deleteLeaveRequest.isLoading) {
      return;
    }
    setSelectedLeaveId(id);
    handleOpenConfirmation();
  };

  const handleConfirmation = (bool: boolean) => {
    if (bool && selectedLeaveId && !deleteLeaveRequest.isLoading) {
      deleteLeaveRequest.mutate(selectedLeaveId);
      setSelectedLeaveId(null);
    }
  };

  return (
    <>
      <div className="flex flex-col flex-1 w-full h-full">
        <div className="overflow-hidden overflow-y-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg h-[calc(100%-64px)]">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 px-3 text-center text-sm font-semibold text-gray-900"
                >
                  Start
                </th>
                <th
                  scope="col"
                  className="py-3.5 px-3 text-center text-sm font-semibold text-gray-900"
                >
                  End
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                >
                  Leave Type
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                >
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {leaveRequests?.map((leaveRequest) => {
                return (
                  <tr key={leaveRequest.id}>
                    <td className="px-3 py-4 text-sm text-center text-gray-500">
                      {leaveRequest.all_day === 0 ? (
                        <>
                          <div className="font-semibold text-center text-gray-900">Full Day</div>
                          <div className="text-center text-gray-500">
                            {formatDateToTimeZone(leaveRequest.start_at, 'dd-MM-y')}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="font-semibold text-center text-gray-900">
                            {formatDateToTimeZone(leaveRequest.start_at, 'HH:mm')}
                          </div>
                          <div className="text-center text-gray-500">
                            {formatDateToTimeZone(leaveRequest.start_at, 'dd-MM-y')}
                          </div>
                        </>
                      )}
                    </td>
                    <td className="px-3 py-4 text-sm text-center text-gray-500">
                      {leaveRequest.all_day === 0 ? (
                        <>
                          <div className="font-semibold text-center text-gray-900">Full Day</div>
                          <div className="text-center text-gray-500">
                            {formatDateToTimeZone(leaveRequest.end_at, 'dd-MM-y')}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="font-semibold text-center text-gray-900">
                            {formatDateToTimeZone(leaveRequest.end_at, 'HH:mm')}
                          </div>
                          <div className="text-center text-gray-500">
                            {formatDateToTimeZone(leaveRequest.end_at, 'dd-MM-y')}
                          </div>{' '}
                        </>
                      )}
                    </td>
                    <td className="px-3 py-4 text-sm text-center text-gray-500">
                      <div className="text-center text-gray-900">
                        {leaveRequest.leave?.title ?? '-'}
                      </div>
                    </td>
                    <td className="px-3 py-4 text-sm text-center text-gray-500">
                      <div className="flex items-center justify-center">
                        <button
                          onClick={() =>
                            handleUpdateRequest(
                              leaveRequest.id,
                              leaveRequest.status,
                              leaveRequest.leave_id,
                              leaveRequest.staff_id,
                            )
                          }
                          disabled={
                            deleteLeaveRequest.isLoading ||
                            // leaveRequest.status === 'approved' ||
                            leaveRequest.is_locked ||
                            leaveRequest.staff?.time_off_type === 'not_allowed'
                          }
                          className={`inline-flex mx-auto capitalize px-2 text-xs font-semibold leading-5 rounded-full text-center ${
                            leaveRequest.status === 'pending' && 'text-gray-800 bg-gray-100'
                          } ${leaveRequest.status === 'declined' && 'text-red-800 bg-red-100'} ${
                            leaveRequest.status === 'approved' && 'text-green-800 bg-green-100'
                          }`}
                        >
                          {leaveRequest.status}
                        </button>
                      </div>
                    </td>
                    <td className="relative py-4 pl-3 pr-4 text-sm font-medium text-center sm:pr-6">
                      {leaveRequest.status === 'pending' && (
                        <>
                          <button
                            type="button"
                            className="mx-2 text-red-400 transition duration-200 ease-in-out hover:text-red-800 disabled:hover:text-red-200 disabled:text-red-200"
                            disabled={
                              leaveRequest.is_locked ||
                              leaveRequest.staff?.time_off_type === 'not_allowed'
                            }
                            onClick={() =>
                              handleRejectRequest(
                                leaveRequest.id,
                                leaveRequest.leave_id,
                                leaveRequest.staff_id,
                              )
                            }
                          >
                            <Tooltip title={'Reject'}>
                              <span>
                                <XMarkIcon className="w-6 h-6" />
                              </span>
                            </Tooltip>
                          </button>
                          <button
                            type="button"
                            className="mx-2 text-green-400 transition duration-200 ease-in-out hover:text-green-800 disabled:hover:text-green-200 disabled:text-green-200"
                            disabled={
                              leaveRequest.is_locked ||
                              leaveRequest.staff?.time_off_type === 'not_allowed'
                            }
                            onClick={() =>
                              handleAcceptRequest(
                                leaveRequest.id,
                                leaveRequest.status,
                                leaveRequest.leave_id,
                                leaveRequest.staff_id,
                              )
                            }
                          >
                            <Tooltip title={'Accept'}>
                              <span>
                                <CheckIcon className="w-6 h-6" />
                              </span>
                            </Tooltip>
                          </button>
                        </>
                      )}
                      <button
                        type="button"
                        className="mx-2 text-red-400 transition duration-200 ease-in-out hover:text-red-800 disabled:hover:text-red-200 disabled:text-red-200"
                        disabled={
                          deleteLeaveRequest.isLoading ||
                          // leaveRequest.status === 'approved' ||
                          leaveRequest.is_locked
                        }
                        onClick={() => handleDeleteLeave(leaveRequest.id)}
                      >
                        <Tooltip title={'Delete'}>
                          <span>
                            <TrashIcon className="w-6 h-6" />
                          </span>
                        </Tooltip>
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="flex items-center justify-between pt-6 text-base text-blue-500">
          <button
            type="button"
            onClick={handleCloseModal}
            className="h-10 text-sm tracking-wide text-white transition bg-red-500 border border-transparent rounded shadow-sm w-28 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Close
          </button>
          <div className="flex items-center">
            <span className="text-sm">Requests:</span>
            <span className="ml-2">{leaveRequests.length ?? 0}</span>
          </div>
        </div>
      </div>
      <ReasonModal show={showReasonModal} closeModal={closeReasonModal} addReason={addReason} />
      <ChangeStatusModal
        show={showChangeStatusModal}
        closeModal={closeChangeStatusModal}
        updateLeaveRequest={updateLeaveRequest}
        currentStatus={reqDetails?.status}
        reqDetails={reqDetails}
        setReqDetails={setReqDetails}
      />
      <Confirmation
        show={showConfirmation}
        closeModal={handleCloseConfirmation}
        confirm={handleConfirmation}
      />
    </>
  );
};

export default LeaveRequestsList;
