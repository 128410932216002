import { useQuery } from '@tanstack/react-query';
import staffsService from '../services/staffs-service';
import { useActiveWorkspaceSlice } from '../store/active-workspace-slice';

export function useEmployee(id: number | string | undefined | null) {
  const activeWorkspace = useActiveWorkspaceSlice((state) => state.workspace);

  return useQuery(
    [staffsService.staffQueryKey, id, activeWorkspace?.id],
    () => staffsService.getStaff(id!),
    {
      enabled: Boolean(id),
      refetchOnWindowFocus: false,
      staleTime: 1000 * 30,
    },
  );
}
